import { styled } from 'styled-components';

const BannerContainer = styled.div`
  background-color: #cce5ff;
  color: #004085;
  padding: 0.75rem 3.25rem 0.65rem 1.25rem;
  margin: 2rem auto;
  max-width: 1140px;
  border: 1px solid #b8daff;
  border-radius: 0.25rem;
  position: relative;
  font-size: 1rem;
  line-height: 1.5;
`;

const CloseButton = styled.button`
  color: #004085;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1.25rem;
`;

const StyledP = styled.p`
  padding: 0.25rem 0;
`;

const Banner = ({ isVisible, onClose }: { isVisible: boolean; onClose: () => void }) => {
  if (!isVisible) return null;

  return (
    <BannerContainer>
      <StyledP>
        We have recently moved to <strong>optomrx.com.au</strong>. If you have any old bookmarks
        using optomrx.net, please update them.
      </StyledP>
      <CloseButton type="button" aria-label="close alert" onClick={onClose}>
        <svg
          className="Modal__icon"
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#004085"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
        </svg>
      </CloseButton>
    </BannerContainer>
  );
};

export default Banner;
